<template>
  <div class="container">
    <div class="header-container">
      <header>
        <van-nav-bar
            title="温馨提示"
            left-text="返回"
            right-text=""
            left-arrow
            @click-left="onBack"
        />
      </header>
    </div>
    <div class="section-box">
      <div class="main-top">
        <h1>温馨提示</h1>
        <div class="hint-desc">
          <div>Hi {{phone}} {{hoursTip}}</div>
          <div class="van-multi-ellipsis--l3">
            您未绑定户号，请先绑定户号或使用快捷查交水费！
          </div>
        </div>
      </div>
      <van-cell title="户号绑定" :icon="require('../../assets/images/finger.png')" is-link to="../user/bindAccount"/>
      <van-cell title="快捷查交水费" :icon="require('../../assets/images/finger.png')" is-link to="./convenient"/>
    </div>
    <Loading :loding-txt="lodingTxt" :is-show="isShow"/>
  </div>
</template>

<script>
import Loading from "../../components/logding";
import {getKey} from "../../utils/cookie";
export default {
  name: "remind",
  components: {Loading},
  data(){
    return{
      isShow : false,
      lodingTxt: '加载中...',
      phone:'',
      hoursTip:''
    }
  },
  created() {
    //重新获取登录状态
    const isLogin = (getKey(process.env.VUE_APP_H5_IS_LOGIN) || 'false') == 'true' ? true : false
    this.isLogin = isLogin
    if (isLogin){
      const userinfo = JSON.parse(getKey(process.env.VUE_APP_USER_INFO) || '{}')
      if (userinfo && userinfo.account){
        //获取手机号码
        this.phone = userinfo.account.phone
        this.phone = this.phone.substr(0,3) + "****" + this.phone.substr(this.phone.length-4)
        //获取选中的绑定的户号
        const bindIndex = parseInt(getKey(process.env.VUE_APP_H5_BIND_CHECK_INDEX) || '0')
        let i = 0
        userinfo.waterUserList.forEach(item => {
          if (i == bindIndex){
            //console.log(item)
            this.bindInfo = item
          }
          i ++;
        })
      }
    }
    this.getMycount()
  },
  methods:{
    onBack : function (){
      this.$router.go(-1);
    },
    getMycount () {
      const that = this
      let date = new Date()
      if (date.getHours() >= 0 && date.getHours() < 12) {

        that.hoursTip = "上午好"

      } else if (date.getHours() >= 12 && date.getHours() < 18) {

        that.hoursTip = "下午好"

      } else {

        that.hoursTip = "晚上好"

      }
    }
  }
}
</script>

<style scoped>
.main-top{padding: 10px; border-bottom:1px solid #ebebeb; color: black; height: 120px;}
.hint-desc{color: #333333;font-size: 14px;line-height: 30px;height: 30px; padding-left: 20px;padding-right: 20px;}
</style>